<template>
  <div class="summit_details">
    <!-- previous page button -->
    <section class="section-back-button">
      <a href="/"><span class="fa fa-long-arrow-left"></span></a>
    </section>
    <section class="site-container" id="bout">
      <div class="container-fluid col-lg-12 col-md-12 col-sm-12">
        <div class="position">
          <div class="col-12 row mx-md-5 mx-0">
            <div class="col-md-2 col-0"></div>
            <div class="col-md-8 col-sm-12 d-flex justify-content-center">
              <img src="@/assets/images/912/912Rwanda - Grant 2020 graphics._001.png" alt="" class="expand"
                style="height: 100%; object-fit: cover" />
            </div>
            <div class="col-md-2 col-0"></div>
          </div>
        </div>
        <!-- introduction section  -->
        <div class="my-4 mx-md-5 mx-1 px-2">
          <h1 class="font-weight-bold">912Rwanda</h1>
          <p class="build">
            912Rwanda is a novel online ambulance solution with a primary goal
            of reducing the travel time of emergency ambulances in Kigali,
            Rwanda. <br />912Rwanda provides control of prehospital operations
            in a single unified platform for dispatch, ambulances, and emergency
            departments.
          </p>
        </div>
        <h2 class="h2 text-center my-4 px-md-2 px-1 col-md-9 col-12 mx-auto">
          912Rwanda: Transforming Emergency Response in Rwanda
        </h2>

        <!-- project overview section  -->
        <div class="my-4 mx-md-5 mx-1 px-2">
          <h3 class="font-weight-bold">
            Revolutionizing Pre-Hospital Care with 912Rwanda
          </h3>
          <p>
            The Rwanda Build Program (RWBuild) proudly introduces 912Rwanda, a
            novel and innovative online dispatch and ambulance solution
            transforming emergency medical services in Kigali, Rwanda. At the
            heart of this project lies the unwavering dedication to diminishing
            preventable mortality rates by integrating state-of-the-art
            technology with effective pre-hospital operations management. The
            genesis of 912Rwanda is rooted in a synergetic collaboration with
            key stakeholders: the Ministry of Health Rwanda Biomedical Center,
            international partners, and the Rwanda National Ambulance Service,
            SAMU (Service D’Aide Medicale Urgence, division under RBC/MOH).
          </p>
          <p class="mt-3">
            The planning for 912Rwanda commenced in 2018, driven by the
            objective to significantly reduce ambulances' response and travel
            time during emergencies.This ambitious project has garnered over
            $5,000,000 in funding, underpinning a 7-year strategic plan. This
            plan focuses on developing and implementing crucial infrastructure
            software with SAMU, meticulously designed and rigorously tested.
          </p>
          <p class="mt-3">
            We proudly reached a significant milestone in early 2023 with the
            successful launch of 912Rwanda. SAMU received the comprehensive
            dispatch and ambulance system with overwhelmingly positive reviews,
            as its roots and core needs come directly from the 912Rwanda 2
            processes and guidance of SAMU leadership and staff - it is their
            product for how they function.
          </p>
          <p class="mt-3">
            As we celebrate this success, we remain committed to the continuous
            improvement and expansion of the project. The upcoming Phase 2
            promises to enhance further and strengthen the capabilities of
            912Rwanda, ensuring the highest standard of emergency medical
            services for the people of Kigali, Rwanda.
          </p>

          <!-- timeline section  -->
          <div class="timeline">
            <div class="heading-timeline font-bold">
              <h3 class="font-weight-bold">Comprehensive Project Phases</h3>
            </div>
            <section class="cd-timeline js-cd-timeline">
              <div class="container max-width-lg cd-timeline__container" style="margin-top: -24hpx">
                <div class="cd-timeline__block">
                  <div class="cd-timeline__img cd-timeline__img--picture"></div>
                  <div class="cd-timeline__content text-component" data-aos="fade-down" data-aos-delay="50"
                    data-aos-duration="500" data-aos-easing="ease-in-out" data-aos-mirror="true" data-aos-once="false">
                    <p class="color-contrast-medium">
                      In this foundational planning phase, RWBuild, in
                      collaboration with the Rwanda Ministry of Health SAMU
                      Emergency Ambulance Division and international experts,
                      laid the groundwork for 912Rwanda. The innovative and
                      novel solution and plans as a larger system to solve many
                      problems and advance the current pre-hospital systems
                      garnered official support from the Ministry of Health,
                      marking a significant milestone in our journey.
                    </p>

                    <div class="flex justify-between items-center">
                      <span class="cd-timeline__date">
                        Ideation and Early Prototyping Phase (2018-2020)</span>
                    </div>
                  </div>
                </div>
                <div class="cd-timeline__block">
                  <div class="cd-timeline__img cd-timeline__img--movie"></div>
                  <div class="cd-timeline__content text-component" data-aos="fade-down" data-aos-delay="50"
                    data-aos-duration="500" data-aos-easing="ease-in-out" data-aos-mirror="true" data-aos-once="false">
                    <p class="color-contrast-medium">
                      This phase saw the development and launch of a data
                      collection app and service for SAMU, funded by the US VCU
                      School of Medicine. It was a crucial step in refining our
                      prototypes and preparing for the NIH grant application,
                      ensuring that our solutions aligned with emergency
                      services needs.
                    </p>
                    <div class="flex justify-between items-center">
                      <span class="cd-timeline__date">Final Prototyping & Pre-Launch Data Collection App
                        (2020- 2022)</span>
                    </div>
                  </div>
                </div>
                <div class="cd-timeline__block">
                  <div class="cd-timeline__img cd-timeline__img--picture"></div>
                  <div class="cd-timeline__content text-component" data-aos="fade-down" data-aos-delay="50"
                    data-aos-duration="500" data-aos-easing="ease-in-out" data-aos-mirror="true" data-aos-once="false">
                    <p class="color-contrast-medium">
                      With Funding from the US NIH (National Institute of Health
                      Fogarty Institute), a partnership with the Rwanda
                      Biomedical Center / Ministry of Health, and Dr. Sudha
                      Jayaraman with the University of Utah Center for Global
                      Surgery, this phase focused on developing the foundational
                      function and wider services for dispatch and ambulance
                      focused on deployment to reduce time. Communication and
                      tracking to ensure SAMU reaches the patient rapidly. This
                      phase began a new era in emergency response efficiency in
                      Rwanda.
                    </p>

                    <div class="flex justify-between items-center">
                      <span class="cd-timeline__date">
                        Phase G1-NIH (2021-2023)</span>
                    </div>
                  </div>
                </div>
                <div class="cd-timeline__block">
                  <div class="cd-timeline__img cd-timeline__img--movie"></div>
                  <div class="cd-timeline__content text-component" data-aos="fade-down" data-aos-delay="50"
                    data-aos-duration="500" data-aos-easing="ease-in-out" data-aos-mirror="true" data-aos-once="false">
                    <p class="color-contrast-medium">
                      With funding from the UK’s NIHR, collaboration with global
                      health experts, and Professor Justine Davies with the
                      Institute of Applied Health Research at the University of
                      Birmingham, this phase will expand 912Rwanda’s
                      capabilities, including advanced patient info tracking and
                      server decision logic to reduce the time it takes in
                      matching the best facility the patient needs to go to.
                      This phase promises to enhance the precision and efficacy
                      of emergency responses. This phase includes extra study
                      years and many Rwandan organizations and government
                      agencies for support, including MOH, RBC, Rwanda
                      University, UGHE, and more. More information on the grant
                      funding:
                      <a style="word-break: break-word"
                        href="https://www.birmingham.ac.uk/research/applied-health/research/rwanda912/about.aspx"
                        target="_blank">https://www.birmingham.ac.uk/research/applied-health/research/rwanda912/about.aspx</a>
                    </p>
                    <div class="flex justify-between items-center">
                      <span class="cd-timeline__date">Phase G2-NIHR (2022-2025)</span>
                    </div>
                  </div>
                </div>
              </div>
            </section>
            <div class="">
              <p class="disprlay mfx-3">
                RWBuild is committed to being involved throughout the project's
                7-year plan. All project stages of ideation, planning,
                development, testing, and ongoing maintenance.
              </p>
            </div>
          </div>
          <div class="pb-2 pt-3">
            <div class="process-912 justify-content-cente row">
              <div class="col-md-5 col-sm-12 p-3">
                <img src="@/assets/images/912/912RW Team Feb 2023 - 2 (DONT USE).png" alt="" class="expand" style="
                    border: 1px solid rgb(241, 241, 241);
                    height: 100%;
                    /* object-fit: contain; */
                  " />
              </div>
              <div class="col-md-7 col-sm-12 p-3">
                <img src="@/assets/images/912/912RW_Data_Collection_Launch_1_1808x958.png" alt="" class="expand" style="
                    border: 1px solid rgb(241, 241, 241);

                    height: 100%;
                    /* object-fit: contain; */
                  " />
              </div>
            </div>
          </div>
          <div class="">
            <h3 class="font-weight-bold">Impact Measurement and Reporting</h3>
            <p>
              Post-launch, we, with partners and SAMU will rigorously measure
              and report the impact of 912Rwanda, focusing on reduced deployment
              times and overall journey efficiency. <br />This data-driven
              approach will allow continuous improvement and scaling of our
              solutions. Without question (as we already see the results), the
              story of this 912Rwanda platform will tell the story of how Rwanda
              can innovate on pre-heath systems and how the Ministry of Health
              RBC SAMU division can lead the way.
            </p>
            <p>
              An exciting goal that is coming to be true is to build a system of
              pre-deploy that dispatches an ambulance faster than the average in
              America!
            </p>
          </div>
          <div class="">
            <h3 class="font-weight-bold">Phase G1-NIH Features</h3>
          </div>
          <ul class="build ml-md-4 ml-3">
            <li>
              The Development of Safty-Critical Software and a Foundational
              Server Platform
            </li>
            <li>Web Dispatch Interface for Real-Time Monitoring</li>
            <li>Mobile Apps for Ambulances with Advanced Functionality</li>
            <li>Novel Community Pin for Precise Location Identification</li>
            <li>
              SMS Notification System for Efficient Facility Communication
            </li>
          </ul>
          <div class="">
            <h3 class="font-weight-bold">Phase G2-NIHR Features</h3>
          </div>
          <ul class="build ml-md-4 ml-3">
            <li>Comprehensive Patient Information Tracking</li>
            <li>
              Server Decision Suggestion Logic for Optimal Facility Allocation
            </li>
            <li>EmergencyDepartment Resource Tracking</li>
            <li>User-Friendly Emergency Department Interface</li>
          </ul>
          <div class="government-support">
            <div>
              <h3 class="font-weight-bold">
                The Importance of Academic Partnerships
              </h3>
            </div>
            <p>
              The collaboration with esteemed academic institutions is a
              cornerstone of the 912Rwanda project. These partnerships ensure
              rigorous research, methodological precision, and meticulous data
              collection, which are crucial for validating the effectiveness of
              our solutions. <br />The involvement of academics in
              public-private partnerships like ours is beneficial and essential
              for creating impactful, sustainable, and scalable healthcare
              solutions.
            </p>
            <p class="mt-2 media-links d-flex flex-column" style="margin-top:-20px">
             
              <a href="https://www.birmingham.ac.uk/"
                target="_blank">
                University of Birmingham</a>
              <a href="https://uofuhealth.utah.edu/"
                target="_blank">
                University of Utah Health</a>
            </p>
          </div>
          <div class="">
            <h3 class="font-weight-bold">Timeline and Partners</h3>
            <p>
              Strong partnerships and a commitment to excellence mark our
              journey from ideation to implementation. Each project phase has
              been meticulously planned and executed in collaboration with
              government bodies, international health experts, and academic
              institutions, ensuring a holistic approach to emergency medical
              care.
            </p>
          </div>
          <div class="">
            <h3 class="font-weight-bold">Endorsements and Support</h3>
            <p>
              912Rwanda has garnered the full backing and support of the SAMU,
              the Rwanda Emergency Ambulance Service, and the Rwanda Ministry of
              Health (MOH). This wideranging support underscores our solution's
              potential to redefine pre-hospital care in Rwanda with multiple
              MOUs and multinational agreements over several years.
            </p>
          </div>
          <div class="">
            <h3 class="font-weight-bold">Conclusion</h3>
            <p>
              912Rwanda is not just a technological breakthrough; it's a beacon
              of hope and a catalyst for change in Rwanda's healthcare system.
              With robust support from a spectrum of institutions and experts,
              we are poised to enhance emergency medical response and save
              countless lives. Rob Rickard of RWBuild articulately says, "We
              really can rethink what ambulance dispatch and pre-hospital
              support can be." We invite you to be part of this transformative
              journey.
              <strong>For more details and collaboration opportunities, contact
                us.</strong>
            </p>
          </div>
          <div class="">
            <h3 class="font-weight-bold">Media Links</h3>
            <div class="d-flex flex-column" style="margin-top:-15px">
              <a href="https://www.newtimes.co.rw/article/3570/news/technology/local-tech-company-secures-rwf4bn-to-speed-hospital-transfers"
                target="_blank"  style="text-decoration:none">
                New Times</a>
              <a href="https://www.birmingham.ac.uk/news/2024/rwanda-launches-ambulance-communication-system-that-could-save-many-lives"
                target="_blank" style="text-decoration:none">
                University of Birmingham</a>
              <a href="https://uofuhealth.utah.edu/surgery/globalsurgery/news/2024/05/u-researchers-help-implement-potentially-life-saving-ambulance"
                target="_blank"  style="text-decoration:none">
                University of Utah Health</a>
            </div>
          </div>
        </div>
      </div>
      <div class="mx-md-5 mx-3 pb-2 px-2">
        <h3 class="font-weight-bold ml-md-3 ml-0">Slide Solutions</h3>
        <div class="process-912 justify-content-center px-md-4 px-1 row">
          <div class="col-md-6 col-sm-12 p-3">
            <img src="@/assets/images/912/912Rwanda - Grant 2020 graphics._002.png" alt="" class="expand img-responsive"
              style="
                height: 100%;
                width: 100%;
                object-fit: cover;
                border: 1px solid rgb(241, 241, 241);
              " />
          </div>
          <div class="col-md-6 col-sm-12 p-3">
            <img src="@/assets/images/912/912Rwanda - Grant 2020 graphics._003.png" alt="" class="expand img-responsive"
              style="
                height: 100%;
                width: 100%;
                object-fit: cover;
                border: 1px solid rgb(241, 241, 241);
              " />
          </div>
          <div class="col-md-6 col-sm-12 p-3">
            <img src="@/assets/images/912/912Rwanda - Grant 2020 graphics._004.png" alt="" class="expand" style="
                height: 100%;
                width: 100%;
                object-fit: contain;
                border: 1px solid rgb(241, 241, 241);
              " />
          </div>
          <div class="col-md-6 col-sm-12 p-3">
            <img src="@/assets/images/912/912Rwanda - Grant 2020 graphics._005.png" alt="" class="expand" style="
                height: 100%;
                width: 100%;
                object-fit: contain;
                border: 1px solid rgb(241, 241, 241);
              " />
          </div>
        </div>
      </div>
      <!-- gallery section  -->
      <div class="container-fluid">
        <div class="widget my-4 mx-md-5 mx-1 px-2">
          <h3 class="font-weight-bold ml-md-0 ml-0">Gallery</h3>
          <div class="row pt-3">
            <div class="col-md-12">
              <div class="event-info-widget">
                <div class="event-carousel ">
                  <Carousel :autoplay="5000" :items-to-show="2.8" :wrap-around="true">
                    <Slide v-for="slide in images" :key="slide">
                      <div class="carousel__i,mtem  event-image mx-2 ">
                        <img :id="slide.id" :src="require(`@/assets/images/912/${slide.img_name}`)
                          " style="
                            /* height: 100%; */
                            width: 100%;
                            /* object-fit: cover; */
                          " />
                      </div>
                    </Slide>

                    <template #addons>
                      <Navigation />
                    </template>
                  </Carousel>

                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="parteners-fly col-md-9 col-sm-12 d-flex justify-content-center mx-auto my-md-5 py-md-5 my-0 py-4"
        style="height: auto; width: 100%">
        <img src="@/assets/images/912/912RW Partners - Screenshot.png" alt="" class="expand-paterners" />
      </div>
    </section>
    <!-- <footer_comp /> -->
  </div>
</template>
<script>
import { Carousel, Navigation, Slide } from "vue3-carousel";
import "vue3-carousel/dist/carousel.css";
export default {
  metaInfo: {
    title: "912Rwanda",
    meta: [
      {
        name: "description",
        content:
          "Join RWBuild in revolutionizing emergency ambulance services in Rwanda with 912Rwanda, a unified platformdesigned to save live",
      },
      {
        property: "og:title",
        content: "912Rwanda",
      },
      {
        property: "og:description",
        content:
          "Join RWBuild in revolutionizing emergency ambulance services in Rwanda with 912Rwanda, a unified platformdesigned to save live",
      },
      {
        property: "twitter:title",
        content: "912Rwanda",
      },
      {
        property: "twitter:description",
        content:
          "Join RWBuild in revolutionizing emergency ambulance services in Rwanda with 912Rwanda, a unified platformdesigned to save live",
      },
    ],
  },
  name: "code-summit",
  components: {
    Carousel,
    Slide,
    Navigation,
  },
  data() {
    return {
      images: [
        {
          id: "3",
          img_name: "912RW Pic Exports 3.png",
        },
        {
          id: "4",
          img_name: "912Rwanda-Pic.3-Feedback-Nov.28.2020.png",
        },
        {
          id: "5",
          img_name: "912Rwanda-Pic.4-Feedback-Nov.19.2020.png",
        },
        {
          id: "6",
          img_name: "912Rwanda-Pic.8-Feedback-Oct.10.png",
        },
        {
          id: "7",
          img_name: "912Rwanda-Pic.9-Feedback-Nov.19.2020.png",
        },
        {
          id: "8",
          img_name: "912RW Pic Exports community pin 2023.png",
        },
      ],
      activeSlider: {
        image: "",
      },
    };
  },
};
</script>
<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
.media-links a {
  text-decoration: none;
}

.media-links a:hover {
  text-decoration: none;
}

ul li {
  list-style: none;
  line-height: 1.75rem;
}

ul li::before {
  content: "\2022";
  color: #118fd5;
  font-weight: bold;
  display: inline-block;
  width: 0.65em;
  font-size: 24px;
}

h1 {
  padding-bottom: 5px;
  border-bottom: 5px solid #118fd5;
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
}

.cd-timeline__img--movie {
  background-color: #007bff !important;
  background-color: #007bff !important;
}

.header {
  margin-top: 20px;
  margin-bottom: 20px;
  font-size: 35px !important;
}

.heading {
  border-bottom: 5px solid #118fd5;
  width: 300px;
  margin-top: 20px;
  margin-bottom: 20px;
}

.heading h2 {
  font-size: 28px !important;
}

.heading-timeline h2 {
  font-size: 28px !important;
}

.font-weight-bold {
  font-size: 20px;
  margin-top: 20px;
  margin-bottom: 20px;
}

img.expand {
  width: 38em;
}

img.expand-paterners {
  width: 100%;
  object-fit: contain;
}

p {
  font-size: 17px !important;
  font-family: "Proxima Nova";
  color: #000 !important;
}

ul,
li {
  list-style-type: disc;
  font-size: 17px;
  font-family: "Proxima Nova";
  color: #000 !important;
}

.display {
  font-size: 22px;
}

.section-back-button {
  background: #fff;
  position: relative;
  padding-left: 5%;
  padding-right: 5%;
  padding-top: 20px;

  .fa {
    font-size: 40px;
    color: #118fd5;
    cursor: pointer;
  }
}

.swiper {
  width: 100%;
  height: 100%;
}

.swiper-slide {
  text-align: center;
  font-size: 18px;
  background: #fff;
  display: -webkit-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  -webkit-justify-content: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  -webkit-align-items: center;
  align-items: center;
}

.swiper-slide img {
  display: block;
  width: 100%;
  height: 100%;
  object-fit: cover;
}

h3 {
  padding-bottom: 5px;
  border-bottom: 5px solid #118fd5;
  width: fit-content;
}

.h2 {
  font-size: 28px;
  font-family: proxima-nova, sans-serif;
  font-style: normal;
  font-weight: 700;
}

.event-image {
  height: 360px;
  width: 90%;
  margin-left: -12px;
}


@import "~@/assets/timeline/style.css";

.cd-timeline {
  background-color: transparent !important;

  .cd-timeline__content {
    border: 1px solid #ddd !important;
    box-shadow: none;
  }

  .container {
    max-width: 100%;
    padding-right: 0px;
    padding-left: 0px;
  }

  .text-component p {
    color: #000 !important;
    font-size: 17px !important;
    font-family: "Proxima Nova";
  }

  .cd-timeline__date {
    font-family: "Proxima Nova";
  }

  .process-912 {
    display: flex !important;
    flex-direction: column !important;
  }
}

@media (min-width: 1200px) {
  .container {
    max-width: 100%;
  }
}

@media screen and (max-width: 1024px) {
  .event-image {
    height: auto;
    width: 100%;
  }

  .section-back-button {
    background: #fff;
    position: relative;
    padding-left: 12%;
    padding-right: 5%;
    padding-top: 20px;

    .fa {
      font-size: 40px;
      color: #118fd5;
      cursor: pointer;
    }
  }
}

@media screen and (min-width: 800px) and (max-width: 1024px) {

  .pl-md-2,
  .px-md-2 {
    padding-left: 2.8rem !important;
  }
}

@media screen and (min-width: 280px) and (max-width: 768px) {
  .h2 {
    font-size: 21px !important;
    font-family: proxima-nova, sans-serif;
    font-style: normal;
    font-weight: 700;
    text-align: inherit !important;
  }
}

.carousel__icon {
  display: none !important;
}

.carousel__next {
  color: red !important;
}
</style>

