<template>
  <div>
  <SplashPage />
</div>
</template>

<script>
import SplashPage from "./components/SplashPage.vue";
export default {
  name: "App",
  components: {
    SplashPage,
  },
};
</script>


